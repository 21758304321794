const allowedResizeDomains = ['cdn.mangas.io', 'd1c7nfvurwnudr.cloudfront.net'];

// Il est possible d'utiliser 5 sous domaines cloudinary :
// res-1.cloudinary.com, res-2.cloudinary.com, ..., res-5.cloudinary.com
// En distribuant les images sur ces sous domaines, on peut augmenter le nombre de requêtes simultanées
// et accélérer la vitesse de chargement des pages.
const DOMAIN_COUNT = 5;

const urlToPrefix = url => {
  // Retourner un nombre entre 1 et 5 en fonction de l'url
  // Ce nombre doit toujours être le même pour une même URL pour des problématiques de cache
  return (
    (url
      .split('')
      .map(char => char.charCodeAt(0))
      .reduce((a, b) => a + b, 0) %
      DOMAIN_COUNT) +
    1
  );
};

function nearestMultipleOf(number, multiple) {
  return Math.ceil(number / multiple) * multiple;
}

function getCloudinaryUrl(url, config) {
  const clamp = config.clamp || 1;
  const width = config.width
    ? `,w_${nearestMultipleOf(config.width, clamp)}`
    : '';
  const height = config.height
    ? `,h_${nearestMultipleOf(config.height, clamp)}`
    : '';

  const format = config.format ? `/f_${config.format}` : '/f_auto';
  const quality = config.quality ? `/q_${config.quality}` : '';
  const domain_index = urlToPrefix(url);
  return `https://res-${domain_index}.cloudinary.com/mangasio/image/fetch/c_limit${width}${height}${format}${quality}/${url}`;
}

function getUploadcareUrl(url, config) {
  const clamp = config.clamp || 1;

  const width = Math.min(3000, nearestMultipleOf(config.width || 3000, clamp));

  const height = Math.min(
    3000,
    nearestMultipleOf(config.height || 3000, clamp)
  );

  return `https://573fd8781b885c4764a1.ucr.io/-/preview/${width}x${height}/${url}`;
}

export function getResizedUrl(url, config) {
  const parsedUrl = new URL(url);
  if (!allowedResizeDomains.includes(parsedUrl.hostname)) return url;

  if (process.env.REACT_APP_IMAGE_PROVIDER === 'uploadcare')
    return getUploadcareUrl(url, config);
  return getCloudinaryUrl(url, config);
}

export function withPixelDensity(value) {
  const pixelDensity = window.devicePixelRatio || 1;
  return value * pixelDensity;
}
