import React from 'react';
import './toggleSwitch.scss';

const ToggleSwitch = ({ onChange }) => {
  return (
    <div className="mio-toggle">
      <input type="checkbox" id="toggle" onChange={onChange} />
      <label htmlFor="toggle" className="switch" />
    </div>
  );
};

export default ToggleSwitch;
