import React from 'react';
import classnames from 'classnames';
import './discoverycard.scss';
import Tag from '../Tag/Tag';

const DiscoveryCard = ({
  className,
  size = 'large',
  picture,
  tags = [],
  loading = false,
  ...props
}) => {
  const style = {};
  if (!loading && picture) style.backgroundImage = `url(${picture})`;
  return (
    <div
      className={classnames('mio-discovery-card', { [size]: true }, className)}
      {...props}
    >
      <div className="image-container">
        <div className="image" style={style} />
      </div>
      {!loading && (
        <>
          <div className="tags">
            {tags.map(tag => (
              <Tag key={tag} size={size === 'small' ? 'small' : 'medium'}>
                {tag}
              </Tag>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default DiscoveryCard;
