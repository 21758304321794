import PricingCardNew from 'Components/PricingCard/PricingCardNew';
import './VoucherCard.scss';
import { TitleHeader } from 'Components';

export default function VoucherCard() {
  return (
    <div className="voucher-container">
      <PricingCardNew
        className="voucher-card"
        title={
          <>
            <TitleHeader
              className="giftCard-title"
              title="Nos cartes cadeaux à offrir 🎁"
              subtitle="Un cadeau manga à petit prix ! "
            />
            <img
              width="200"
              data-aos="fade-up"
              className="coffee-success"
              src="https://cdn.mangas.io/images/coffee/voucher-success-min.png"
              alt=""
            />
          </>
        }
        link="/carte-cadeau"
        linkText="Offrir une carte cadeau"
        version="voucher"
      />
    </div>
  );
}
