import React, { useState } from 'react';
import classnames from 'classnames';
import { useFlags } from 'hooks';

import './Settings.scss';

import CancelSubscriptionSlide from './Slides/CancelSubscriptionSlide';
import ChangePasswordSlide from './Slides/ChangePasswordSlide';
import MainSlide from './Slides/MainSlide';
import PaymentMethodSlide from './Slides/PaymentMethodSlide';
import ProfileSlide from './Slides/ProfileSlide';
import ReferralSlide from './Slides/ReferralSlide';
import SubscriptionManagementSlide from './Slides/SubscriptionManagementSlide';
import SubscriptionSlide from './Slides/SubscriptionSlide';
import VoucherSlide from './Slides/VoucherSlide';
import VoucherCodeSlide from './Slides/VoucherCodeSlide';
import VoucherSuccessSlide from './Slides/VoucherSuccessSlide';
import RemoveAccountSlide from './Slides/RemoveAccountSlide';
import RemoveAccountCodeSlide from './Slides/RemoveAccountCodeSlide';
import NotificationSlide from './Slides/NotificationSlide';
import HelpSlide from './Slides/HelpSlide';
import FamilySlide from './Slides/FamilySlide';
import FamilyMemberSlide from './Slides/FamilyMemberSlide';
import FamilyAddSlide from './Slides/FamilyAddSlide';
import FamilyDeleteSlide from './Slides/FamilyDeleteSlide';
import FamilyQuitSlide from './Slides/FamilyQuitSlide';

const Settings = props => {
  const [activeSlides, setActiveSlides] = useState(['main']);
  const [currentSlideProps, setCurrentSlideProps] = useState({});
  const { flag: noProfileFlag } = useFlags('NO_USER_PROFILE');
  const { flag: noPromoFlag } = useFlags('NO_PROMO');
  const { flag: allowFamilyFlag } = useFlags('ALLOW_FAMILY');
  const navigation = {
    push: (slide, slideProps) => {
      setCurrentSlideProps(slideProps);
      setActiveSlides([...activeSlides, slide]);
    },
    pop: (nb = 1) => {
      setActiveSlides(activeSlides.slice(0, -1 * nb));
    },
    replace: slide => setActiveSlides([...activeSlides.slice(0, -1), slide]),
  };

  function getProps(name) {
    const index = activeSlides.indexOf(name);
    const displayed = index !== -1;
    const shifted = displayed && index < activeSlides.length - 1;
    return {
      ...props,
      ...currentSlideProps,
      flags: {
        noProfile: noProfileFlag,
        noPromo: noPromoFlag,
        allowFamily: allowFamilyFlag,
      },
      navigation,
      active: displayed,
      className: classnames({
        displayed,
        shifted,
      }),
    };
  }

  return (
    <div className="Settings">
      <MainSlide {...getProps('main')} />
      <ProfileSlide {...getProps('profile')} />
      <FamilySlide {...getProps('family')} />
      <FamilyMemberSlide {...getProps('family_member')} />
      <FamilyDeleteSlide {...getProps('family_delete')} />
      <FamilyQuitSlide {...getProps('family_quit')} />
      <FamilyAddSlide {...getProps('family_add')} />
      <HelpSlide {...getProps('help')} />
      <SubscriptionSlide {...getProps('subscription')} />
      <ReferralSlide {...getProps('invitation')} />
      <NotificationSlide {...getProps('notifications')} />
      <ChangePasswordSlide {...getProps('change_password')} />
      <RemoveAccountSlide {...getProps('remove_account')} />
      <RemoveAccountCodeSlide {...getProps('remove_code')} />
      <SubscriptionManagementSlide {...getProps('subscription_management')} />
      <CancelSubscriptionSlide {...getProps('cancel_subscription')} />
      <PaymentMethodSlide {...getProps('payment_method')} />
      <VoucherSlide {...getProps('voucher')} />
      <VoucherCodeSlide {...getProps('voucher_code')} />
      <VoucherSuccessSlide {...getProps('voucher_success')} />
    </div>
  );
};

export default Settings;
