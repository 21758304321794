import React from 'react';
import classnames from 'classnames';
import './cornerTag.scss';

/**
 * CornerTag for indicating an item classment
 */
const CornerTag = ({ variant, ...props }) => {
  return (
    <span
      className={classnames('mio-cornertag', { [variant]: true })}
      {...props}
    >
      {props.children}
    </span>
  );
};

export default CornerTag;
