import React from 'react';
import classnames from 'classnames';

import './Drawer.scss';

const Drawer = ({ children, className, ...props }) => (
  <div className={classnames('mio-drawer', className)} {...props}>
    {children}
  </div>
);

function Left(props) {
  return <div className="left" {...props} />;
}
function Right(props) {
  return <div className="right" {...props} />;
}

export default Object.assign(Drawer, {
  Left,
  Right,
});
