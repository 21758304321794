import React from 'react';
import classnames from 'classnames';
import './bloc.scss';

import Text from '../Text/Text';

const Bloc = ({
  title,
  title_right = null,
  invertTitles = false,
  pre_header = null,
  subtitle,
  mobile = false,
  className,
  children,
  ...props
}) => (
  <div className={classnames('mio-bloc', { mobile }, className)} {...props}>
    {pre_header}
    {(!!title || !!title_right || !!subtitle) && (
      <div className={classnames('header', { invertTitles })}>
        <div className="title_bloc">
          <Text as="h2" type="display" size="small" bold mobile={mobile}>
            {title}
          </Text>
          {title_right}
        </div>
        <Text size="small" mobile={mobile} className="subtitle">
          {subtitle}
        </Text>
      </div>
    )}
    <div className="content">{children}</div>
  </div>
);

export default Bloc;
