import React from 'react';
import ReactPlayer from 'react-player';
import { Page, ResponsiveText } from 'UI';
import { Footer, LandingMediasLine, TitleHeader } from 'Components';
import { Button } from 'design-system';

import './MediaPage.scss';

export default function MediaPage() {
  return (
    <>
      {/* HEADER */}
      <div className="header-media">
        <TitleHeader
          title="ESPACE MÉDIAS"
          subtitle="Les médias en parlent : découvrez à travers des articles et reportages comment Mangas.io transforme l’accès aux mangas numériques."
        />
        <a href="https://www.notion.so/Kit-presse-Mangas-io-0e77f8f3ae19446f84f2b783733aaf3b">
          <Button variant="primary" label="Télécharger notre kit presse" />
        </a>
      </div>
      <Page id="MediaPage">
        {/* PARTNERS */}
        <div className="partners">
          <TitleHeader
            title="La pépite manga dont tout le monde parle (beaucoup)"
            subtitle="À la TV, à la Radio ou dans la presse, Mangas.io est partout ! 📢"
          />
          <LandingMediasLine />
        </div>

        {/* BLOC VIDEO */}
        <div className="wrapper-video">
          <TitleHeader
            title="Mangas.io sur le devant de la scène !"
            subtitle="On parle de nous... beaucoup et partout ! Découvrez nos réponses aux questions des journalistes !"
          />
          <div className="video">
            <ReactPlayer
              style={{
                border: '10px solid rgba(255,255,255,0.5)',
                borderRadius: '10px',
              }}
              url="https://www.youtube.com/watch?v=kTpqMuEIfsE"
              playing={false}
              controls={false}
              width={'100%'}
              height={'500px'}
            />
          </div>
        </div>

        {/* KIT PRESSE */}
        <TitleHeader title="Mangas.io dans les médias - Rejoignez l’aventure !" />
        <div className="wrapper-press">
          <div className="press-text">
            <ResponsiveText
              type="text"
              size="medium"
              style={{ marginBottom: '20px' }}
            >
              On en parle,
              <span style={{ fontWeight: 'bold' }}> et vous ?</span>
            </ResponsiveText>
            <ResponsiveText type="text" size="medium">
              Téléchargez notre kit presse pour en savoir plus.
            </ResponsiveText>
          </div>
          <div className="press-img"></div>
        </div>
        <div className="press-cta">
          <a href="https://www.notion.so/Kit-presse-Mangas-io-0e77f8f3ae19446f84f2b783733aaf3b">
            <Button variant="primary" label="Télécharger notre kit presse" />
          </a>
        </div>

        {/* CTA */}
        <div className="wrapper-cta">
          <TitleHeader title="Vous souhaitez nous parler de votre projet ?" />
          <a href="mailto:pro@mangas.io">
            <Button variant="primary" label="Nous contacter" />
          </a>
        </div>
      </Page>

      <Footer />
    </>
  );
}
