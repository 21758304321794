import React from 'react';
import CarrouselWrapper from 'Components/CarrouselWrapper/CarrouselWrapper';
import './PublisherCarrousel.scss';
import { usePartners, useFlags } from 'hooks';
import { ViewportOnly, range } from 'utils';

function PublisherCarrousel({ noTitle }) {
  const partners = usePartners();
  if (!partners.length) return null;

  return (
    <CarrouselWrapper
      className="carrousel-publisher"
      title={noTitle ? '' : 'Tes éditeurs préférés'}
    >
      {partners
        .filter(partner => partner.is_publisher)
        .map((publisher, key) => (
          <a
            key={key}
            href={publisher.internal_url || '/'}
            className="publisher-bloc"
          >
            <img
              className="publisher-img"
              src={publisher.img.url}
              alt={publisher.img.alt}
            />
          </a>
        ))}
    </CarrouselWrapper>
  );
}

function PublisherCarrouselViewportOnly({ noTitle, isInView }) {
  if (!isInView)
    return (
      <CarrouselWrapper
        className="carrousel-publisher"
        title="Tes éditeurs préférés"
      >
        {range(6).map(key => (
          <a key={key} className="publisher-bloc"></a>
        ))}
      </CarrouselWrapper>
    );
  return <PublisherCarrousel noTitle={noTitle} />;
}

function PublisherCarrouselWrapper({ noTitle }) {
  const { flag: noPromoFlag, loading } = useFlags('NO_PROMO', true);

  if (loading || noPromoFlag) return null;

  return (
    <ViewportOnly>
      <PublisherCarrouselViewportOnly noTitle={noTitle} />
    </ViewportOnly>
  );
}

export default PublisherCarrouselWrapper;
