import React from 'react';
import classnames from 'classnames';
import './separator.scss';

/**
 * Separator component
 */
const Separator = ({ children, ...props }) => {
  return (
    <span className={classnames('mio-separator')} {...props}>
      {children}
    </span>
  );
};

export default Separator;
