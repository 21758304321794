import React from 'react';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { CarrouselWrapper } from 'Components';
import { useQuery, useResponsive } from 'hooks';
import {
  range,
  getResizedUrl,
  withPixelDensity,
  CustomErrorBoundary,
} from 'utils';

import { DiscoveryCard } from 'design-system';
import { shuffle } from 'utils/random';

const GET_COLLECTIONS_LINES = gql`
  query getCollectionsLines($filter: FilterCollectionInput) {
    featuredCollections(filter: $filter) {
      _id
      image {
        url
      }
      mangas {
        _id
        slug
        categories {
          label
          level
        }
      }
    }
  }
`;

function DiscoveryBlockConnected(props) {
  const { is } = useResponsive();
  const { data, loading } = useQuery(GET_COLLECTIONS_LINES, {
    variables: { filter: { collectionType: 'DISCOVERY' } },
  });

  if (loading) {
    return (
      <CarrouselWrapper title="Les perles à découvrir 💎">
        {range(4).map(key => (
          <DiscoveryCard
            key={key}
            loading={loading}
            size={is('md') ? 'large' : 'small'}
          />
        ))}
      </CarrouselWrapper>
    );
  }
  const shuffledDiscoveries = shuffle(data.featuredCollections).filter(
    collection => collection.mangas.length > 0
  );

  if (shuffledDiscoveries.length === 0) return null;
  return (
    <CarrouselWrapper withShadows title="Les perles à découvrir 💎">
      {shuffledDiscoveries.map(discovery => {
        const manga = discovery.mangas[0];
        return (
          <Link to={`/lire/${manga.slug}`} key={discovery._id}>
            <DiscoveryCard
              picture={
                discovery.image
                  ? getResizedUrl(discovery.image.url, {
                      width: is('md')
                        ? withPixelDensity(295)
                        : withPixelDensity(250),
                    })
                  : null
              }
              tags={manga.categories
                .filter(cat => cat.level < 2)
                .sort((a, b) => a.level - b.level)
                .slice(0, 3)
                .map(tag => tag.label)}
              size={is('md') ? 'large' : 'small'}
            />
          </Link>
        );
      })}
    </CarrouselWrapper>
  );
}

export default function ShieldedDiscoveryBlockConnected(props) {
  return (
    <CustomErrorBoundary>
      <DiscoveryBlockConnected {...props} />
    </CustomErrorBoundary>
  );
}
