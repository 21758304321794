import React from 'react';
import classnames from 'classnames';
import './progressBar.scss';

const ProgressBar = ({ value, direction = 'ltr', ...props }) => {
  return (
    <div className={'mio-progressbar'} {...props}>
      <div
        className={classnames('mio-progressbar-level', { [direction]: true })}
        style={{ width: `${value}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
