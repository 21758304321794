import React from 'react';
import classNames from 'classnames';

import './Button.scss';

const Button = props => {
  const {
    label,
    icon = null,
    primary,
    gold,
    secondary,
    vertical,
    narrow,
    horizontalLg,
    link,
    large,
    className,
    ...buttonProps
  } = props;
  // // console.log({ buttonProps });
  return (
    <button
      className={classNames('Button', className, {
        primary,
        secondary,
        vertical,
        gold,
        narrow,
        horizontalLg,
        link,
        large,
      })}
      {...buttonProps}
    >
      {icon && <span className="icon">{icon}</span>}
      {label && <span className="label">{label}</span>}
      <span className="effect"></span>
    </button>
  );
};

export default Button;
