import { usePrismicDocumentsByType } from '@prismicio/react';
import * as prismic from '@prismicio/client';

function useLinkedContent(search) {
  const [documents] = usePrismicDocumentsByType('blog_post', {
    predicates: [prismic.predicate.fulltext('my.blog_post.search', search)],
    orderings: { field: 'document.first_publication_date', direction: 'desc' },
    pageSize: 5,
  });
  if (!search) return [];
  return documents ? documents.results : [];
}

export default useLinkedContent;
