import React from 'react';
import classnames from 'classnames';

import './PricingCardNew.scss';

const PricingCardNew = props => {
  const {
    header,
    title,
    subtitle,
    chipText,
    link = '',
    linkText,
    style,
    version,
    selectPlan = null,
    className,
  } = props;

  return (
    <div
      id="PricingCardNew"
      className={classnames(version, className)}
      style={style}
    >
      <div className="background">
        <div className="card-header">
          <span>{header}</span>
        </div>
        <div className="card-content">
          <div className="mainCard">
            <div className="mainCardContent">
              {chipText && <span className="chip">{chipText}</span>}
              {!chipText && <span className="chip hidden"></span>}
              {title}
            </div>
          </div>
          <div className="bottomCard">
            <p className="subtitle">{subtitle}</p>
            {linkText && (
              <div>
                {selectPlan === null ? (
                  <a className="button" rel="noreferrer noopener" href={link}>
                    {linkText}
                  </a>
                ) : (
                  <button className="button" onClick={selectPlan}>
                    {linkText}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingCardNew;
