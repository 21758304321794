import React from 'react';
import { Button } from 'design-system';
import { useFeatureFlags } from 'hooks';

import { ResponsiveText } from 'UI';
import './LandingBanner.scss';

const LandingBanner = () => {
  const flag = useFeatureFlags(['landing_top_banner']);

  if (!flag.landing_top_banner?.enabled) return null;

  const topBanner = JSON.parse(flag?.landing_top_banner?.value || '[]');

  return (
    <div id="LandingBanner">
      <div>
        <ResponsiveText size="medium" type="display" bold>
          {topBanner.title}
        </ResponsiveText>
        <ResponsiveText size="medium" bold>
          {topBanner.subtitle}
        </ResponsiveText>
      </div>
      <a href={topBanner.link}>
        <Button label={topBanner.button} variant="primary" />
      </a>
    </div>
  );
};

export default LandingBanner;
