import React, { useState } from 'react';
import classnames from 'classnames';
import './ctaImage.scss';

const CtaImage = ({
  link,
  imageLink,
  altText,
  rel,
  target,
  className,
  ...props
}) => {
  const [randomImageIndex] = useState(
    Array.isArray(imageLink) ? Math.floor(Math.random() * imageLink.length) : 0
  );

  const img = Array.isArray(imageLink)
    ? imageLink[randomImageIndex]
    : imageLink;

  return (
    <div className={classnames('mio-ctaImage', className)} {...props}>
      <a href={link} target={target} rel={rel}>
        <img src={img} alt={altText}></img>
      </a>
    </div>
  );
};

export default CtaImage;
