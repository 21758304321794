import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiPlay, mdiInformationOutline } from '@mdi/js';
import { Button, Text, Pill } from 'design-system';

import {
  limitWords,
  getResizedUrl,
  withPixelDensity,
  CustomErrorBoundary,
  track,
} from 'utils';
import { useResponsive, useEventListener } from 'hooks';
import { Responsive } from 'UI';
import { FavoriteButtonConnected } from 'Components';
import './Highlight.scss';
import classnames from 'classnames';

const availablePills = {
  new: {
    label: 'Nouveauté',
    backgroundColor: '#06972e',
  },
  hot: {
    label: 'Brûlant',
    backgroundColor: '#e11f7b',
  },
  anthology: {
    label: 'Anthologie',
    backgroundColor: '#7d0000',
  },
  exclusive: {
    label: 'Exclusivité Mangas.io',
    backgroundColor: '#154ee2',
  },
  temporary: {
    label: 'Titre éphémère',
    style: {
      background: 'linear-gradient(88.5deg, #0B2039 0%, #102969 100%)',
    },
  },
  prepub: {
    label: 'Prépublication gratuite',
    style: {
      color: 'black',
      background: 'linear-gradient(90deg, #F1E96A 0%, #FFFF01 100%)',
    },
  },
  simultrad: {
    label: 'Simultrad',
    style: {
      background: 'linear-gradient(90deg, #EF5E0C 0%, #D82323 100%)',
    },
  },
};

const HighlightWithSkeleton = props => {
  const { loading, highlights } = props;
  const { is } = useResponsive();
  const isMobile = !is('sm');
  const [currentHighlightIndex, setCurrentHighlightIndex] = useState(0);
  const [locked, setLocked] = useState(false);
  const ref = useRef();
  const paddingTop = is('sm') ? '34%' : '150%';

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (highlights && !locked)
        setCurrentHighlightIndex(
          previousHigh => (previousHigh + 1) % highlights.length
        );
    }, 8000);
    return () => {
      clearInterval(intervalId);
    };
  });

  useEventListener('pointerenter', () => setLocked(true), ref);
  useEventListener('pointerleave', () => setLocked(false), ref);

  if (loading || !highlights)
    return <div className="wrapper-highlight" style={{ paddingTop }} />;

  return (
    <div ref={ref} className="wrapper-highlight" style={{ paddingTop }}>
      {highlights
        .filter(highlight => !!highlight.manga)
        .map((highlight, index) => (
          <Highlight
            position={index + 1}
            key={index}
            {...highlight}
            style={{
              transform: `translateX(${(index - currentHighlightIndex) *
                100}%)`,
            }}
          />
        ))}
      {!isMobile && (
        <div className="checkButton-container">
          {highlights.map((_, index) => (
            <button
              key={index}
              className={
                currentHighlightIndex === index
                  ? 'checkButton active'
                  : 'checkButton'
              }
              onClick={() => setCurrentHighlightIndex(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const useResponsiveBanner = (banner, bannerMobile) => {
  const { is } = useResponsive();
  const isMobile = !is('sm');

  let responsiveBanner = banner;
  if (isMobile && bannerMobile) responsiveBanner = bannerMobile;

  return {
    background: `url(${getResizedUrl(responsiveBanner.url, {
      width: withPixelDensity(1730),
    })})`,
  };
};

const Highlight = props => {
  const {
    banner,
    position,
    bannerMobile,
    title,
    className,
    description,
    pills = [],
    manga,
    titleColor,
    style,
  } = props;
  const chapterNo = manga.userHistory ? manga.userHistory.chapter.number : 1;
  const page = manga.userHistory ? manga.userHistory.page + 1 : 1;
  const { is, responsiveSelect } = useResponsive();
  const responsiveBannerStyle = useResponsiveBanner(banner, bannerMobile);
  const buttonSize = responsiveSelect({
    default: 'small',
    lg: 'large',
    md: 'medium',
  });

  const trackingProperties = {
    name: manga.title,
    position: position,
    slug: manga.slug,
    manga_id: manga._id,
    thumbnail: manga.thumbnail?.url,
  };

  return (
    <div
      className={classnames('Highlight', className)}
      style={{
        ...responsiveBannerStyle,
        ...style,
      }}
    >
      <div className="content">
        <div className="pills">
          {pills
            .filter(name => availablePills[name.toLowerCase()])
            .map(name => {
              const { label, backgroundColor, style } = availablePills[
                name.toLowerCase()
              ];
              return (
                <Pill
                  key={name}
                  backgroundColor={backgroundColor}
                  style={style}
                >
                  {label}
                </Pill>
              );
            })}
        </div>
        {manga.logo && (
          <div className="highlight-logo">
            <img
              src={getResizedUrl(manga.logo.url, {
                height: withPixelDensity(100),
              })}
              alt={manga.title}
            />
          </div>
        )}

        {!manga.logo && (
          <div className="title-background">
            <Responsive>
              <Text
                as="h1"
                type="display"
                size="medium"
                bold
                style={{
                  background: `linear-gradient(180deg, rgba(255,255,255,0) 40%, ${titleColor ||
                    '#902237'} 40%)`,
                }}
              >
                {title}
              </Text>
            </Responsive>
          </div>
        )}
        {is('lg') && (
          <Responsive>
            <Text size="small">{limitWords(description, 100)}</Text>
          </Responsive>
        )}
        <div className="button-list">
          <Link to={`/lire/${manga.slug}/${chapterNo}/${page}`}>
            <Button
              variant="primary"
              size={buttonSize}
              icon={<Icon path={mdiPlay} />}
              label="Lire"
              onClick={() =>
                track('Highlight Clicked', {
                  ...trackingProperties,
                  target: 'Read Button',
                })
              }
            />
          </Link>
          <Link to={`/lire/${manga.slug}`}>
            <Button
              size={buttonSize}
              icon={<Icon path={mdiInformationOutline} />}
              label="Détails"
              onClick={() =>
                track('Highlight Clicked', {
                  ...trackingProperties,
                  target: 'Details Button',
                })
              }
            />
          </Link>
          <FavoriteButtonConnected size={buttonSize} mangaId={manga._id} />
        </div>
      </div>
    </div>
  );
};

function ShieldedHighlight(props) {
  return (
    <CustomErrorBoundary>
      <HighlightWithSkeleton {...props} />
    </CustomErrorBoundary>
  );
}

export default ShieldedHighlight;
