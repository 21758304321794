import React from 'react';
import { Carrousel, PlaceholderShape, Text } from 'design-system';
import { Link } from 'react-router-dom';
import { useResponsive } from 'hooks';
import { Responsive } from 'UI';
import './CarrouselWrapper.scss';

function ResponsiveText(props) {
  return (
    <Responsive>
      <Text {...props} />
    </Responsive>
  );
}

function CarrouselWrapper({
  preTitle,
  tab = null,
  title,
  subtitle,
  subtitleLink,
  pill = null,
  loading,
  withShadows = true,
  withControls = true,
  ...props
}) {
  const { is } = useResponsive();
  const isMedium = is('md');

  return (
    <div className="carrousel-wrapper">
      <ResponsiveText type="text" size="small" className="pre-title">
        {preTitle}
      </ResponsiveText>
      <div className="titleBloc">
        <ResponsiveText type="display" size="small" className="--title-s title">
          {loading && !title && (
            <PlaceholderShape
              as="span"
              style={{
                height: '25px',
                width: '210px',
                display: 'inline-block',
              }}
            />
          )}
          {title}
          {pill}
        </ResponsiveText>
        {subtitle && (
          <Link to={subtitleLink} className="subtitleLink">
            <ResponsiveText type="text" size="large" className="subtitle">
              {subtitle}
            </ResponsiveText>
            <ResponsiveText className="subtitleChevron">{`>`}</ResponsiveText>
          </Link>
        )}
      </div>
      {tab}
      <Carrousel
        {...props}
        withShadows={isMedium && withShadows}
        withControls={isMedium && withControls}
      />
    </div>
  );
}

export default CarrouselWrapper;
