import React, { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import gql from 'graphql-tag';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { Button, Text } from 'design-system';

import { FloatingMenu } from 'UI';
import { getResizedUrl, withPixelDensity } from 'utils';
import { useMutation, useProfile } from 'hooks';
import './FamilySlide.scss';

const QUIT_FAMILY = gql`
  mutation quitFamily {
    quitFamily
  }
`;

function LeaveFamilyConfirmDialog({ onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [requestDeletion] = useMutation(QUIT_FAMILY);
  const { refreshProfile } = useProfile();

  const handleOnPress = async e => {
    try {
      setLoading(true);
      await requestDeletion();
      await refreshProfile();
      onSuccess();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Text>
        Es-tu certain.e de vouloir quitter la famille et ainsi renoncer à tes
        avantages ?
      </Text>
      <img
        className="coffeeImg"
        alt="coffee"
        src={getResizedUrl(
          'https://cdn.mangas.io/landing/coffee-question-mark.png',
          {
            width: withPixelDensity(150),
            height: withPixelDensity(250),
          }
        )}
        width={150}
      />
      <Button
        label="Confirmer la suppression"
        variant="primary"
        fullWidth
        onClick={handleOnPress}
        loading={loading}
      />
    </>
  );
}

function LeaveFamilySuccess() {
  return (
    <>
      <Text>Tu as bien quitté la famille et perdu tes avantages Premium !</Text>
      <img
        className="coffeeImg"
        alt="coffee"
        src={getResizedUrl(
          'https://cdn.mangas.io/images/coffee/coffee-sad-corps-min.png',
          {
            width: withPixelDensity(150),
            height: withPixelDensity(250),
          }
        )}
        width={150}
      />
      <Link to="/abonnement" style={{ width: '100%' }}>
        <Button label="Je me réabonne !" variant="gold" fullWidth />
      </Link>
    </>
  );
}

const FamilyQuitSlide = props => {
  const { navigation, className } = props;
  const [displaySuccessScreen, setdisplaySuccessScreen] = useState(false);

  return (
    <div className={classnames(className, 'slide', 'family-slide')}>
      <FloatingMenu.Header
        className="clickable"
        onClick={() => navigation.pop()}
      >
        <span className="back-icon">
          <Icon path={mdiArrowLeft} />
        </span>
        Ma famille
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        <div className="wrapper">
          <div className="delete-container">
            {!displaySuccessScreen && (
              <LeaveFamilyConfirmDialog
                onSuccess={() => setdisplaySuccessScreen(true)}
              />
            )}
            {displaySuccessScreen && <LeaveFamilySuccess />}
          </div>
        </div>
      </FloatingMenu.Body>
    </div>
  );
};

export default FamilyQuitSlide;
