import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './LandingFeatures.scss';
import { getResizedUrl, withPixelDensity } from 'utils';

const LandingFeatures = props => {
  useEffect(() => {
    AOS.init({
      once: true,
      anchorPlacement: 'top-center',
    });

    /*eslint-disable-next-line*/
  }, []);

  return (
    <div id="LandingFeatures">
      <div className="big-card type-1">
        <div
          className="big-card-content"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="illu-mobile custom-illu-preview-mobile">
            <img
              src={getResizedUrl(
                'https://cdn.mangas.io/landing/card1_web.png',
                {
                  width: withPixelDensity(200),
                  height: withPixelDensity(250),
                }
              )}
              alt="feature-card-1-mobile"
            />
          </div>
          <div className="content">
            <h3 className="title">
              Des milliers de tomes au prix d’un manga par mois
            </h3>
            <p>
              Profite de nouvelles sorties chaque jour en utilisant notre app
              iOS et Android sur mobile ou notre plateforme web sur ton grand
              écran pour faire le plein de lecture.
            </p>
          </div>
          <div className="illu custom-illu-preview">
            <img
              src={getResizedUrl(
                'https://cdn.mangas.io/landing/card1_web.png',
                {
                  width: withPixelDensity(650),
                  height: withPixelDensity(500),
                }
              )}
              alt="feature-card-1"
              className="feature-img"
            />
          </div>
        </div>
      </div>

      <div className="big-card type-2 reverse">
        <div className="big-card-content">
          <div className="illu-mobile">
            <img
              src={getResizedUrl(
                'https://cdn.mangas.io/landing/card2_web.png',
                {
                  width: withPixelDensity(200),
                  height: withPixelDensity(250),
                }
              )}
              alt="feature-card-2-mobile"
            />
          </div>
          <div className="content">
            <h3 className="title">Profite d’un confort de lecture ultime</h3>
            <p>
              Notre lecteur a été pensé par des fans de mangas pour des fans de
              mangas. Fini les pubs, profite enfin de tes mangas en HD avec la
              traduction officielle. Mais ce n’est pas tout : tu peux aussi
              profiter du mode hors-ligne !
            </p>
          </div>
          <div className="illu custom-illu-preview">
            <img
              src={getResizedUrl(
                'https://cdn.mangas.io/landing/card2_web.png',
                {
                  width: withPixelDensity(650),
                  height: withPixelDensity(500),
                }
              )}
              alt="feature-card-2"
              className="feature-img"
            />
          </div>
        </div>
      </div>

      <div className="big-card type-3">
        <div className="big-card-content">
          <div className="illu-mobile custom-illu-preview-mobile">
            <img
              src={getResizedUrl(
                'https://cdn.mangas.io/landing/card3_web.png',
                {
                  width: withPixelDensity(200),
                  height: withPixelDensity(250),
                }
              )}
              alt="feature-card-3-mobile"
            />
          </div>
          <div className="content">
            <h3 className="title">
              Découvre des pépites en avant-première avec l’abonnement premium !
            </h3>
            <p>
              Lis les derniers chapitres de tes mangas préférés en
              avant-première et découvre du contenu exclusifs de nos éditeurs
              partenaires en adoptant l’abonnement premium !
            </p>
          </div>
          <div className="illu custom-illu-preview">
            <img
              src={getResizedUrl(
                'https://cdn.mangas.io/landing/card3_web.png',
                {
                  width: withPixelDensity(650),
                  height: withPixelDensity(500),
                }
              )}
              alt="feature-card-3"
              className="feature-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingFeatures;
