import React from 'react';
import classnames from 'classnames';

import './articleCard.scss';

const ArticleCard = ({ picture, title, date, ...props }) => {
  return (
    <div className={classnames('mio-articlecard')} {...props}>
      <div className={'mio-articlecard-imgBlock'}>
        <div style={{ backgroundImage: `url(${picture})` }}></div>
      </div>
      <div className={'mio-articlecard-textBlock'}>
        <span className={'mio-articlecard-title'}>{title}</span>
        <span className="mio-articlecard-date">{date}</span>
      </div>
    </div>
  );
};

export default ArticleCard;
