import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import Icon from '@mdi/react';
import { mdiMagnify, mdiHistory, mdiChevronDown } from '@mdi/js';
import { Image } from 'cloudinary-react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { Button } from 'design-system';

import { Settings, ContinueReadingConnected, Search } from 'Components';
import { Logo, MobileLogo, FloatingMenu, AppStoreButtons } from 'UI';
import { useProfile, useFlags } from 'hooks';

import './Navbar.scss';

const Navbar = props => {
  const { className } = props;
  const [displaySettings, setDisplaySettings] = useState(false);
  const [displayHistory, setDisplayHistory] = useState(false);
  const [displaySearch, setDisplaySearch] = useState(false);

  const { profile, loaded } = useProfile();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(state => !state);
  const { flag: noPromoFlag, loading } = useFlags('NO_PROMO');

  useEffect(() => {
    if (isMenuOpen) disableBodyScroll(null, options);
    else clearAllBodyScrollLocks();
    return clearAllBodyScrollLocks;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const closeAll = () => {
    setDisplayHistory(false);
    setDisplaySettings(false);
    setDisplaySearch(false);
  };

  const options = {
    reserveScrollBarGap: true,
  };

  const defaul_avatar =
    'https://res.cloudinary.com/mangasio/image/upload/v1615984833/user_avatar/defaul_avatar.png';
  const [avatar, setAvatar] = useState(defaul_avatar);

  useEffect(() => {
    setAvatar(profile.avatar ? profile.avatar.url : defaul_avatar);
  }, [profile]);

  useEffect(() => {
    closeAll();
  }, [location]);

  useEffect(() => {
    if (displaySettings || displayHistory || displaySearch)
      disableBodyScroll(null, options);
    else clearAllBodyScrollLocks();
    return clearAllBodyScrollLocks;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displaySettings, displayHistory, displaySearch]);

  const updateAvatar = data => {
    setAvatar(data);
  };

  if (loading) return null;

  return (
    <div className={classNames('Navbar', className)}>
      <div className="side">
        <Link to="/" onClick={closeAll}>
          <Logo className="logo" alt="Mangas.io" />
          <MobileLogo className="logoMobile" alt="Mangas.io" />
        </Link>
      </div>
      <div className="center">
        <nav
          className={classNames('nav-menu', {
            active: isMenuOpen,
          })}
        >
          <NavLink
            className="menu-item"
            activeClassName="active"
            to="/manga-en-ligne/"
          >
            Mangas
          </NavLink>
          <NavLink className="menu-item" activeClassName="active" to="/library">
            Ma Liste
          </NavLink>
          {!noPromoFlag && (
            <NavLink className="menu-item" activeClassName="active" to="/blog/">
              Blog
            </NavLink>
          )}

          <AppStoreButtons className="is-mobile" />
        </nav>
        <span
          onClick={toggleMenu}
          className={classNames('mobile-menu-toggle is-mobile clickable', {
            active: isMenuOpen,
          })}
        >
          <Icon path={mdiChevronDown} />
        </span>
      </div>
      {loaded && (
        <div className="side right">
          {!profile.isActive && (
            <Link to="/abonnement" className="subscription-cta">
              <Button size="medium" variant="gold" label="M'abonner" />
            </Link>
          )}

          <span
            className="icon custom-margin icon-search"
            onClick={() => {
              closeAll();
              setDisplaySearch(!displaySearch);
            }}
          >
            <Icon path={mdiMagnify} />
          </span>

          <span
            className={classNames('icon', 'custom-margin', 'clickable', {
              active: displayHistory,
            })}
            onClick={() => {
              closeAll();
              setDisplayHistory(!displayHistory);
            }}
          >
            <Icon path={mdiHistory} />
          </span>

          <div
            className={classNames('icon', 'avatar', 'clickable', {
              notification: false,
              subscriptionBasic: profile.info?.planInfo?.tier === 'BASIC',
              subscriptionPremium: profile.info?.planInfo?.tier === 'PREMIUM',
              subscriptionFamily: profile.info?.planInfo?.tier === 'FAMILY',
              active: displaySettings,
            })}
          >
            <Image
              className="avatar-image"
              cloudName="mangasio"
              publicId={avatar}
              width="110"
              secure="true"
              alt="User avatar"
              onError={() => {
                setAvatar(defaul_avatar);
              }}
              onClick={() => {
                closeAll();
                setDisplaySettings(!displaySettings);
              }}
            />
          </div>
        </div>
      )}

      <FloatingMenu
        isOpen={displaySearch}
        onClose={() => setDisplaySearch(false)}
      >
        <Search onClose={() => setDisplaySearch(false)} />
      </FloatingMenu>
      <FloatingMenu
        className="menu-settings"
        isOpen={displaySettings}
        onClose={() => setDisplaySettings(false)}
      >
        <Settings updateAvatar={updateAvatar} {...props} />
      </FloatingMenu>
      <FloatingMenu
        isOpen={displayHistory}
        onClose={() => setDisplayHistory(false)}
      >
        <ContinueReadingConnected onClose={closeAll} />
      </FloatingMenu>
    </div>
  );
};

export default Navbar;
