import React from 'react';
import classnames from 'classnames';

import Checkbox from '../Checkbox/Checkbox';

import './RadioBox.scss';

function RadioBox({
  className,
  variant = 'default',
  mobile = false,
  children,
  ...props
}) {
  const _children = React.Children.toArray(children);
  const headerComponents = _children.filter(child => child.props.isHeader);
  const innerComponents = _children.filter(child => !child.props.isHeader);
  return (
    <div
      className={classnames('mio-radio-box', className, variant, { mobile })}
    >
      {headerComponents}
      <Checkbox {...props}>
        <div className="mio-radio-box-content">{innerComponents}</div>
      </Checkbox>
    </div>
  );
}

function Header({ className, isHeader = true, ...props }) {
  return (
    <div className={classnames('mio-radio-box-header', className)} {...props} />
  );
}
function Center({ className, ...props }) {
  return (
    <div className={classnames('mio-radio-box-center', className)} {...props} />
  );
}
function Right({ className, ...props }) {
  return (
    <div className={classnames('mio-radio-box-right', className)} {...props} />
  );
}

export default Object.assign(RadioBox, {
  Center,
  Right,
  Header,
});
