import React, { useRef } from 'react';
import {
  Route,
  useRouteMatch,
  useLocation,
  Switch,
  useHistory,
} from 'react-router-dom';
import { usePrismicDocumentsByType } from '@prismicio/react';
import { Button } from 'design-system';
import classnames from 'classnames';

import {
  Footer,
  SEO,
  // NewsletterForm,
  BlogCard,
  BlogHighlights,
} from 'Components';

import { PostPage } from 'Pages';
import { useFlags, useResponsive } from 'hooks';

import './BlogPage.scss';
import { Page } from 'UI';
import { getResizedUrl, withPixelDensity } from 'utils';

function Blog() {
  const history = useHistory();
  const location = useLocation();
  const { is } = useResponsive();
  const isMobile = !is('sm');
  const { flag: noPromoFlag } = useFlags('NO_PROMO', true);
  const query = new URLSearchParams(location.search);
  const currentPage = query.has('page') ? parseInt(query.get('page')) : 1;
  const [document, { state }] = usePrismicDocumentsByType('blog_post', {
    fetchLinks: ['author.name', 'author.avatar', 'author.role'],
    orderings: { field: 'document.first_publication_date', direction: 'desc' },
    pageSize: 18,
    page: currentPage,
  });
  const loading = state !== 'loaded';
  const posts = !loading ? document.results : [];
  const { total_pages } = document || {};

  const isLastPage = currentPage >= total_pages;
  const isFirstPage = currentPage <= 1;

  const meta = {
    title: 'Mangas.io | Blog',
  };

  const ref = useRef(null);
  const scroll2Top = () => {
    ref.current?.scrollIntoView(true);
    // offset Y
    window.scrollTo(0, window.scrollY - 90);
  };

  if (noPromoFlag) return null;

  return (
    <main className="BlogPage-container">
      <SEO {...meta} />

      <header className="BlogBanner">
        <img
          src={
            isMobile
              ? getResizedUrl(
                  'https://cdn.mangas.io/blog/Highlight-blog-qvema-Mobile.png',
                  {}
                )
              : getResizedUrl(
                  'https://cdn.mangas.io/blog/Highlight-blog-qvema-Desktop.png',
                  { width: withPixelDensity(1570) }
                )
          }
          alt="Highlight blog Mangas.io"
        />
      </header>

      <div className="content-wrapper">
        <div className="BlogHero">
          <BlogHighlights />
        </div>

        {/* <nav className="BlogFilters">
          <Link
            to={`/`}
            className={classNames('link', {
              active: true,
            })}
          >
            Tous
          </Link>
          <Link
            to={`/`}
            className={classNames('link', {
              active: false,
            })}
          >
            Catégorie
          </Link>
          <Link
            to={`/`}
            className={classNames('link', {
              active: false,
            })}
          >
            Catégorie
          </Link>
          <Link
            to={`/`}
            className={classNames('link', {
              active: false,
            })}
          >
            Catégorie
          </Link>
        </nav> */}

        <div className="BlogFlex" ref={ref}>
          {posts.map(post => (
            <div key={post.uid} className="BlogFlex_item">
              <BlogCard key={post.uid} post={post} />
            </div>
          ))}
          {!posts.length &&
            [1, 2, 3].map(post => (
              <div key={post} className="BlogFlex_item">
                <BlogCard />
              </div>
            ))}
        </div>

        <div className="pagination">
          <Button
            className={classnames({ hidden: isFirstPage })}
            onClick={() => {
              query.set('page', currentPage - 1);
              scroll2Top();
              history.push({
                location: location.pathname,
                search: query.toString(),
              });
            }}
            label="Page précédente"
          />

          <Button
            className={classnames({ hidden: isLastPage })}
            onClick={() => {
              query.set('page', currentPage + 1);
              scroll2Top();
              history.push({
                location: location.pathname,
                search: query.toString(),
              });
            }}
            label="Page suivante"
          />
        </div>
      </div>
    </main>
  );
}

function BlogRouter() {
  let match = useRouteMatch();
  return (
    <>
      <Page id="BlogPage">
        <Switch>
          <Route exact path={match.path}>
            <Blog />
          </Route>
          <Route path={`${match.path}/:slug`}>
            <PostPage />
          </Route>
        </Switch>
      </Page>
      <Footer />
    </>
  );
}

export default BlogRouter;
