import React from 'react';
import classnames from 'classnames';
import './indicatorPill.scss';

const IndicatorPill = ({ backgroundColor, style, className, ...props }) => {
  return (
    <span
      className={classnames('mio-indicatorPill', className)}
      {...props}
      style={{ ...style, backgroundColor: backgroundColor }}
    >
      {props.children}
    </span>
  );
};

export default IndicatorPill;
