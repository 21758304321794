import React from 'react';
import Icon from '@mdi/react';
import classnames from 'classnames';

import './Pill.scss';

const Pill = props => {
  const {
    label,
    icon = null,
    color = '#00aabb',
    background,
    bgColor,
    className,
    onClick,
  } = props;

  let style = {};
  if (background) {
    style.background = background;
  } else if (bgColor) {
    style.backgroundColor = bgColor;
  } else {
    style.backgroundColor = `${color}35`;
  }
  return (
    <span
      className={classnames('Pill', className)}
      style={{ color }}
      onClick={onClick}
    >
      <span className="background" style={style}>
        {icon && <Icon path={icon} style={{ fill: color }} />}
        <span className="label">{label}</span>
      </span>
    </span>
  );
};

export default Pill;
