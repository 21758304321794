import React from 'react';
import classnames from 'classnames';
import './tag.scss';

const Tag = ({ className, size = 'medium', ...props }) => {
  return (
    <span
      className={classnames('mio-tag', className, { [size]: true })}
      {...props}
    />
  );
};

export default Tag;
