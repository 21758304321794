import React from 'react';
import CarrouselWrapper from 'Components/CarrouselWrapper/CarrouselWrapper';
import './LandingMediasLine.scss';
import { ViewportOnly, range } from 'utils';
import { useSinglePrismicDocument } from '@prismicio/react';

function LandingMediasLineWrapper(props) {
  return (
    <ViewportOnly>
      <LandingMediasLineViewportOnly {...props} />
    </ViewportOnly>
  );
}

function LandingMediasLineViewportOnly({ isInView, ...props }) {
  if (!isInView)
    return (
      <CarrouselWrapper className="carrousel-medias" {...props}>
        {range(6).map(key => (
          <a key={key} className="medias-bloc"></a>
        ))}
      </CarrouselWrapper>
    );
  return <LandingMediasLine {...props} />;
}

function LandingMediasLine(props) {
  const [document, { state }] = useSinglePrismicDocument('medias');

  const medias = state === 'loaded' ? document.data.medias : [];
  return (
    <CarrouselWrapper className="carrousel-medias" {...props}>
      {medias.map((media, key) => (
        <a
          key={key}
          target="_blank"
          href={media.link.url || '/'}
          className="medias-bloc"
        >
          <img className="medias-img" src={media.img.url} alt={media.img.alt} />
        </a>
      ))}
    </CarrouselWrapper>
  );
}

export default LandingMediasLineWrapper;
