import React from 'react';
import ReactPlayer from 'react-player';
import { Button } from 'design-system';
import { Page, ResponsiveText } from 'UI';
import {
  CarrouselWrapper,
  Footer,
  LandingPartnersLine,
  TitleHeader,
} from 'Components';
import { getResizedUrl, withPixelDensity } from 'utils';
import { useResponsive } from 'hooks';

import './PartnerPage.scss';

function Card({ img, number }) {
  return (
    <div className="card-content">
      <img
        src={getResizedUrl(img, {
          width: withPixelDensity(260),
          height: withPixelDensity(260),
        })}
        width="130px"
        height="130px"
        alt="card"
      />
      <div className="card-content-text">
        <ResponsiveText type="display" size="medium" bold>
          {number}
        </ResponsiveText>
        <ResponsiveText type="text" size="medium" bold>
          visites
        </ResponsiveText>
      </div>
    </div>
  );
}

function AnimatedCard({ title, description, number }) {
  return (
    <div className="card-content animate">
      <div>
        <div className="card-content-text">
          <ResponsiveText type="text" size="large" bold>
            {title}
          </ResponsiveText>
          <ResponsiveText type="text" size="small">
            {description}
          </ResponsiveText>
        </div>

        <div className="card-content-visitors">
          <ResponsiveText type="text" size="small">
            {number}
          </ResponsiveText>
        </div>
      </div>
    </div>
  );
}

const visitors = [
  {
    img: 'https://cdn.mangas.io/landing/fest1.png',
    number: '+80k',
  },
  {
    img: 'https://cdn.mangas.io/landing/fest2.png',
    number: '+200k',
  },
  {
    img: 'https://cdn.mangas.io/landing/fest3.png',
    number: '+250k',
  },
  {
    img: 'https://cdn.mangas.io/landing/fest4.png',
    number: '+200k',
  },
];

const activities = [
  {
    title: 'Animation',
    description: 'Présenter votre produit sur place ou lors d’une conférence',
    number: '3K VISITEUR.ICE.S',
  },
  {
    title: 'Spot vidéo',
    description:
      'Diffuser un ou plusieurs spots sur les écrans du Quartier Manga',
    number: '50K VISITEUR.ICE.S',
  },
  {
    title: 'PLV XXL',
    description: 'Affiche grand format dédiée à votre produit',
    number: '100K VISITEUR.ICE.S',
  },
  {
    title: 'Stand dédié',
    description:
      'Un stand brandé et sa gestion par une équipe dédiée si nécessaire',
    number: '3K VISITEUR.ICE.S',
  },
  {
    title: 'Exposition',
    description: 'Proposer une exposition sur un thème lié à votre marque',
    number: '30K VISITEUR.ICE.S',
  },
  {
    title: 'Entrée customisée',
    description: 'Personnalisez l’une des portes d’entrée avec votre image',
    number: '100K VISITEUR.ICE.S',
  },
  {
    title: 'Concours',
    description:
      'Organiser un concours sur un stand et collecter des prospects',
    number: '40K VISITEUR.ICE.S',
  },
  {
    title: 'Flyer',
    description: 'Votre produit sur un flyer dédié ou sur le plan officiel',
    number: '50K VISITEUR.ICE.S',
  },
];

const digitals = [
  {
    title: 'Newsletter M.io',
    description:
      'Une section dédiée dans notre newsletter pour présenter votre produit',
    number: '200K ABONNÉ.E.S',
  },
  {
    title: 'Réseaux Sociaux',
    description:
      'Posts, stories, concours... Il y a de nombreuses façons d’atteindre notre public !',
    number: '40K ABONNÉ.E.S',
  },
  {
    title: 'Placements in-app',
    description:
      'Nous pouvons placer des blocs thématiques liés à votre marque à plusieurs endroits.',
    number: '10K À 100K VUES/MOIS',
  },
  {
    title: 'Notification Mobile',
    description: 'Une notification push qui renvoie à notre article de blog',
    number: '12K VUES/ENVOI',
  },
  {
    title: 'Blog',
    description:
      'Un article dédié à notre partenariat et à votre produit. Très bon pour votre référencement !',
    number: '1K À 8K VUES/MOIS',
  },
];

export default function PartnerPage() {
  const { is } = useResponsive();
  const isMobile = !is('lg');
  return (
    <>
      {/* HEADER */}
      <div className="header-partner">
        <ResponsiveText type="display" size="large" bold>
          PLUS QU’UNE APPLICATION,
        </ResponsiveText>
        <ResponsiveText type="display" size="large" bold>
          REJOIGNEZ L’EXPERIENCE MANGA N°1 !
        </ResponsiveText>
        <a href="mailto:pro@mangas.io">
          <Button
            variant="primary"
            label="Devenez notre partenaire, dès maintenant !"
          />
        </a>
      </div>
      <Page id="PartnerPage">
        {/* PARTNERS */}
        <div className="partners">
          <TitleHeader
            title="On nous fait confiance"
            subtitle="Vous aussi, devenez notre prochain partenaire !"
          />
          <LandingPartnersLine />
        </div>

        {/* QM ABOUT */}
        <div className="about">
          <TitleHeader
            title="Le Quartier Manga, qu’est-ce que c’est ?"
            subtitle="L’expérience manga immersive"
          />

          <div
            className="wrapper-about"
            style={{ flexDirection: isMobile && 'column-reverse' }}
          >
            <div className="left">
              {!isMobile && (
                <img
                  src={getResizedUrl(
                    'https://cdn.mangas.io/landing/logoqm.png',
                    {
                      width: withPixelDensity(400),
                      height: withPixelDensity(400),
                    }
                  )}
                  width="200px"
                  alt="logo Quartier Manga"
                />
              )}

              <ResponsiveText type="text" size="small">
                Le Quartier Manga est un concept unique pensé pour les
                conventions: réunissant des partenaires clés, des éditeurs et
                des sponsors, il constitue un axe commercial fort et un outil
                marketing pour Mangas.io !
              </ResponsiveText>
            </div>
            <div className="right">
              <ReactPlayer
                style={{
                  border: '10px solid rgba(255,255,255,0.5)',
                  borderRadius: '10px',
                }}
                playing={false}
                controls={false}
                width={'100%'}
                height={'300px'}
                url={'https://youtu.be/f1TwG7fDMqU'}
              />
            </div>
          </div>

          <div className="wrapper-numbers">
            <ResponsiveText type="text" size="medium" className="lighter">
              Les chiffres de fréquentation
            </ResponsiveText>
            <CarrouselWrapper withShadows={false}>
              {visitors.map((visitor, index) => (
                <Card key={index} {...visitor} />
              ))}
            </CarrouselWrapper>
          </div>
        </div>

        {/* BRANDING*/}
        <TitleHeader
          title="Comment mettre en valeur votre marque ?"
          subtitle="Des milliers de possibilités, sur mesure et selon vos besoins"
        />
        <div className="wrapper-brand">
          <img
            className="logoQM"
            src={getResizedUrl('https://cdn.mangas.io/landing/logoqm.png', {
              width: withPixelDensity(400),
              height: withPixelDensity(400),
            })}
            width="200px"
            alt="logo Quartier Manga"
          />
          <div>
            <ResponsiveText type="display" size="small" bold>
              Le Quartier Manga, pour rendre votre marque palpable
            </ResponsiveText>
            <ResponsiveText type="text" size="large" className="subtitle">
              Le Quartier Manga est la meilleure occasion de mettre en avant
              votre marque, et ce, de manière ludique et impactante. Nous nous
              déplaçons dans plusieurs conventions par an, en France et en
              Belgique, permettant ainsi de faire profiter à votre marque d’un
              écho à grande échelle. De nombreuses possibilités existent suivant
              vos besoins et envies !
            </ResponsiveText>
          </div>
        </div>
        <div className="wrapper-plan">
          <img
            src={getResizedUrl(
              'https://cdn.mangas.io/landing/qm-plan-light.png',
              {
                width: withPixelDensity(800),
                height: withPixelDensity(800),
              }
            )}
            width="80%"
            height="auto"
            alt='Plan du "Quartier Manga"'
          />
          <div className="plan-cards">
            {activities.map((activity, index) => (
              <AnimatedCard key={index} {...activity} />
            ))}
          </div>
          <a href="mailto:pro@mangas.io">
            <Button
              variant="primary"
              label="Cliquez ici pour rejoindre le Quartier Manga !"
            />
          </a>
        </div>

        {/* DIGITAL BRANDING */}
        <div className="wrapper-digital">
          <TitleHeader title="Une mise en avant digitale pour étendre votre marque à tous les horizons" />
          <ResponsiveText type="text" size="large" className="subtitle">
            En plus de rendre votre marque palpable en contact direct avec le
            public, nous proposons également un partenariat digital pour vous
            proposer une plus grande visibilité, et cela par plusieurs biais :
            nos réseaux sociaux évidemment, mais aussi des mises en avant à
            travers l’application pour toucher ??? votre public cible.
          </ResponsiveText>
          <div className="wrapper-visibility">
            <div className="left">
              <img
                src={getResizedUrl(
                  'https://cdn.mangas.io/landing/mio-computer-2.png',
                  {
                    width: withPixelDensity(400),
                    height: withPixelDensity(400),
                  }
                )}
                width="100%"
                alt="Illustration d'un ordinateur avec l'application Mangas.io"
              />
            </div>
            <div className="right">
              {digitals.map((digital, index) => (
                <AnimatedCard key={index} {...digital} />
              ))}
            </div>
          </div>
        </div>

        {/* CTA */}
        <div className="wrapper-cta">
          <TitleHeader title="Vous souhaitez nous parler de votre projet ?" />
          <a href="mailto:pro@mangas.io">
            <Button variant="primary" label="Nous contacter" />
          </a>
        </div>
      </Page>
      <Footer />
    </>
  );
}
