import React from 'react';
import { CarrouselWrapper } from 'Components';
import { InlineCard, Tag, CornerTag } from 'design-system';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { range, getResizedUrl, withPixelDensity } from 'utils';

import './MangaCards.scss';

function toChunks(input, chunkSize) {
  let chunks = [];
  for (let i = 0; i < input.length; i += chunkSize) {
    chunks.push(input.slice(i, i + chunkSize));
  }
  return chunks;
}

function getCornerTag(js_date) {
  const date = dayjs(js_date);
  const today = dayjs();
  const twoDaysAgo = today.subtract(2, 'day');
  const fourDaysAgo = today.subtract(4, 'day');
  const inTwoDays = today.add(2, 'day');
  const dateIsPast = date.isBefore(today);
  const dateIsRecent = dateIsPast && date.isAfter(fourDaysAgo);
  const dateIsNear = date.isAfter(twoDaysAgo) && date.isBefore(inTwoDays);
  const variant = dateIsPast && dateIsRecent ? 'primary' : 'default';
  const displayDate = dateIsNear
    ? dayjs(date).fromNow()
    : dayjs(date).format('ddd D MMM.');
  return <CornerTag variant={variant}>{displayDate}</CornerTag>;
}

function getSubtitle(release) {
  if (release.firstChapterNumber === release.lastChapterNumber)
    return `Chapitre ${release.firstChapterNumber}`;
  else
    return `Chapitres ${release.firstChapterNumber} à ${release.lastChapterNumber}`;
}

function MangaCardsReleases({ loading, releases, tab, title, pill, preTitle }) {
  if (loading)
    return (
      <CarrouselWrapper
        preTitle={preTitle}
        title={title}
        loading={true}
        tab={tab}
        className="mangaCards-container"
      >
        {range(3).map(key => (
          <div className="pair" key={key}>
            {range(2).map(key2 => (
              <InlineCard className="mangaCard" key={key2} loading={true} />
            ))}
          </div>
        ))}
      </CarrouselWrapper>
    );

  const releasesPairs = toChunks(releases, 2);

  return (
    <CarrouselWrapper
      title={title}
      withShadows
      pill={pill}
      tab={tab}
      preTitle={preTitle}
      className="mangaCards-container"
    >
      {releasesPairs.map((pair, key) => (
        <div className="pair" key={key}>
          {pair.map(release => {
            const manga = release.manga;
            const CategoriesPills = () => {
              return manga.categories
                .toSorted((a, b) => a.level - b.level)
                .slice(0, 3)
                .map(cat => (
                  <Tag key={cat.slug} size="small">
                    {cat.label}
                  </Tag>
                ));
            };

            return (
              <Link to={`/lire/${manga.slug}`} key={release._id}>
                <InlineCard
                  className="mangaCard"
                  title={manga.title}
                  picture={getResizedUrl(release.thumbnail.url, {
                    width: withPixelDensity(82),
                    height: withPixelDensity(118),
                  })}
                  subtitle={getSubtitle(release)}
                  cornerTag={getCornerTag(release.releaseDate)}
                >
                  <CategoriesPills />
                </InlineCard>
              </Link>
            );
          })}
        </div>
      ))}
    </CarrouselWrapper>
  );
}

export default MangaCardsReleases;
