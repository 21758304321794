import React from 'react';
import classnames from 'classnames';
import './inlinecard.scss';
import { PlaceholderShape } from '../';

const InlineCard = ({
  picture,
  title,
  subtitle,
  loading,
  children,
  cornerTag,
  className,
  ...props
}) => {
  return (
    <div className={classnames('mio-inlineCard', className)} {...props}>
      <div className={'imgBlock'}>
        {picture && <div style={{ backgroundImage: `url(${picture})` }}></div>}
      </div>
      <div className={'textBlock'}>
        {loading && (
          <>
            <PlaceholderShape
              className="title"
              style={{ width: '124px', height: '20px', borderRadius: '8px' }}
            />
            <PlaceholderShape
              className="subtitle"
              style={{ width: '72px', height: '15px', borderRadius: '8px' }}
            />
          </>
        )}
        {!loading && (
          <>
            <span className="title">{title}</span>
            <span className="subtitle">{subtitle}</span>
            <span className="children">{children}</span>
          </>
        )}
      </div>

      <div className="cornerTag">{cornerTag}</div>
    </div>
  );
};

export default InlineCard;
