import React from 'react';
import { Link } from 'react-router-dom';
import { AppStoreButtons } from 'UI';
import { Button } from 'design-system';
import Icon from '@mdi/react';
import { mdiCrownOutline } from '@mdi/js';
import './LandingSupportCoffee.scss';

const LandingSupportCoffee = ({ customCTA }) => {
  return (
    <div id="LandingSupportCoffee">
      <div className="big-card type-4">
        <div className="big-card-content">
          <div className="illu-mobile">
            <img
              src="https://cdn.mangas.io/landing/coffee-manga-mobile-min.png"
              width="238"
              height="240"
              alt="coffee reading a book"
            />
          </div>
          <div className="content">
            <h2 className="title">
              Soutiens les créateur.ice.s qui te font rêver !
            </h2>
            <p>
              En lisant des mangas sur Mangas.io, tu contribues directement à
              soutenir le travail de tes mangakas préférés. En plus d’être juste
              et viable, notre modèle est l’un des plus rémunérateurs pour les
              ayants droit !
            </p>
            <br />
            <div className="big-card-cta">
              <div className="buttons-container">
                {customCTA ? (
                  customCTA
                ) : (
                  <>
                    <Link to="/signup">
                      <Button
                        size="large"
                        fullWidth
                        variant="gold"
                        label="Activer mon essai gratuit"
                        icon={<Icon path={mdiCrownOutline} />}
                        id="cta_signup"
                      />
                    </Link>
                  </>
                )}
              </div>
              {!customCTA && <AppStoreButtons passCulture />}
            </div>
          </div>
          <div className="illu">
            <img
              src="https://cdn.mangas.io/landing/coffee-manga-min.png"
              width="324"
              height="328"
              alt="coffee reading a book"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingSupportCoffee;
