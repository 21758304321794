import React from 'react';
import CarrouselWrapper from 'Components/CarrouselWrapper/CarrouselWrapper';
import { usePartners, useFlags } from 'hooks';
import './LandingPartners.scss';

function LandingPartnersLine(props) {
  const partners = usePartners();
  const { flag: noPromoFlag } = useFlags('NO_PROMO', true);
  if (noPromoFlag) return null;
  return (
    <div id="LandingPartnersLine">
      <CarrouselWrapper className="carrousel-publisher" {...props}>
        {partners
          .filter(partner => !partner.is_publisher)
          .map((publisher, key) => (
            <a
              key={key}
              href={publisher.url.url || '/'}
              className="publisher-bloc"
            >
              <img
                className="publisher-img"
                src={publisher.img.url}
                alt={publisher.img.alt}
              />
            </a>
          ))}
      </CarrouselWrapper>
    </div>
  );
}

export default LandingPartnersLine;
