import { ResponsiveText } from 'UI';
import classnames from 'classnames';
import './TitleHeader.scss';

function TitleHeader({ title, subtitle, className }) {
  return (
    <div className={classnames('titleHeader', className)}>
      <ResponsiveText type="display" bold>
        {title}
      </ResponsiveText>
      {subtitle && (
        <ResponsiveText type="text" size="large" className="subtitleText">
          {subtitle}
        </ResponsiveText>
      )}
    </div>
  );
}
export default TitleHeader;
